<template>
    <v-row
     v-if="'error' in values[0]"  
     justify="center"
    >
         <div class="mt-5">
            <h3>{{$t('Unable to show AnalogInputs')}}</h3>
            <h6>{{`Error: ${values[0].error}`}}</h6>
        </div>
    </v-row>
    <v-row v-else justify="center">
        <analog-block-status
        v-for="k, b in values" 
        :status="k" 
        :key="b"
        class="mt-5"
        />      
    </v-row>
</template>

<script>
import AnalogBlockStatus from '../components/AnalogBlockStatus.vue'

export default {
    name:"AnalogSettings",
    components: {
        AnalogBlockStatus,
    },
    
    computed: {
        values() {
            return this.$store.state.analog_status
        }
    }
    
}
</script>

<style scoped>

</style>