<template>
  <v-card max-width="650">
    <v-card-title style="background-color: #196619;" class="mb-2 white--text">
      {{$t('Analog block address') + ' ' + status.block}}
    </v-card-title>
    <v-card-text>
    <v-row >
      <v-col cols="6">
        <v-icon large :color="status.input_neg ? 'red':'grey'">
          mdi-led-on
        </v-icon>
        <p>{{$t('input')}} IN-</p>
      </v-col>
      <v-col cols="6">
        <v-icon large :color="status.input_pos ? 'red':'grey'">
          mdi-led-on
        </v-icon>
        <p>{{$t('input')}} IN+</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field dense outlined readonly  suffix="mV" :label="$t('voltage') + ' IN-'" v-model="status.voltage_neg"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field dense outlined readonly  suffix="mV" :label="$t('voltage') + ' IN+'" v-model="status.voltage_pos"></v-text-field>
      </v-col>
    </v-row>
    <v-col>
      <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header disable-icon-rotate @click="loadData">
          {{$t("Analog Block Settings")}}
          <template v-slot:actions>
            <v-icon color="vinland">
              mdi-cog
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container class="mx-auto">
            <v-form>
              <v-col cols="12">
                <p>{{$t("Input function")}}</p>
                <v-radio-group v-model="setting.mode" mandatory row>
                  <v-radio
                    :label="$t('Normal')"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    :label="$t('Differential')"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-switch
                    v-model="setting.invert_in_neg"
                    :label="$t('Invert IN-')"
                    :true-value="1"
                    :false-value="0"
                    :value="0"
                    hide-details
                  ></v-switch>
                  <v-switch
                     v-model="setting.invert_in_pos"
                    :label="$t('Invert IN+')"
                    :true-value="1"
                    :false-value="0"
                    :value="0"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-switch
                    v-model="setting.count_in_neg"
                    :label="$t('Count IN-')"
                    :true-value="1"
                    :false-value="0"
                    :value="0"
                    hide-details
                  ></v-switch>
                  <v-switch
                     v-model="setting.count_in_pos"
                    :label="$t('Count IN+')"
                    :true-value="1"
                    :false-value="0"
                    :value="0"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-switch
                    v-model="setting.map_ext_neg"
                    :label="$t('Map EXT IN-')"
                    :true-value="1"
                    :false-value="0"
                    :value="0"
                    hide-details
                  ></v-switch>
                  <v-switch
                     v-model="setting.map_ext_pos"
                    :label="$t('Map EXT IN+')"
                    :true-value="1"
                    :false-value="0"
                    :value="0"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
              
              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field 
                  dense 
                  outlined  
                  suffix="mV"
                  :label="$t('Threshold low') + ' IN-'" 
                  v-model.number="setting.voltage_neg_lo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field 
                    dense 
                    outlined 
                    suffix="mV"
                    :label="$t('Threshold high') + ' IN-'" 
                    v-model.number="setting.voltage_neg_hi"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field 
                    dense 
                    outlined 
                    suffix="mV" 
                    :label="$t('Threshold low') + ' IN+'" 
                    v-model.number="setting.voltage_pos_lo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field
                    dense 
                    outlined 
                    suffix="mV"  
                    :label="$t('Threshold high') + ' IN+'" 
                    v-model.number="setting.voltage_pos_hi">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-btn @click="readData" dark color="indigo" :loading="loading">{{$t('Read setting from DB')}}</v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn @click="loadData" dark color="teal">{{$t('Read setting from BB')}}</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"  align="right">
                  <v-btn @click="save" :loading="loading_save" dark :disabled="!check" :color="check ? 'green' : 'grey'">{{$t('Save')}}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    </v-card-text>
  </v-card> 
</template>

<script>
export default {
  name: 'AnalogBlockStatus',
  props: ['status'],

  data() {
    return {
      loading: false,
      loading_save: false
      //  setting:{              
      //   voltage_pos_lo: 1,
      //   voltage_pos_hi: 1,
      //   voltage_neg_lo: 1,
      //   voltage_neg_hi: 1,
      //   invert_in_pos: 1,
      //   invert_in_neg: 1,
      //   count_in_pos: 1,
      //   count_in_neg: 1,
      //   map_ext_neg: 1,
      //   map_ext_pos: 1,
      //   address: 1,
      //   mode: 1,
      // }
    }
  },
  methods: {
   
    async save() {
     
      try {
          this.loading_save = true
          await this.$store.dispatch('setAnalogSetting', this.setting)
          const ans = await this.$http.post(`/analogsetting`, this.setting)
          console.log("ans.data", ans.data)
          this.setting = ans.data
        } catch (e) {
          console.log(e)
          this.loading_save = false
        } finally {
          this.loading_save = false
        }
    },
    async loadData() {
      console.log("loaddata", this.status.block)
      await this.$store.dispatch('getAnalogSetting', this.status.block)
    }, 
    async readData() {
      console.log("loaddata", this.status.block)
       try {
          this.loading = true
          const ans = await this.$http.get(`/analogsetting/${parseInt(this.status.block)}`)
          this.setting = ans.data
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
    } 
    
             
  },
  computed: {
    setting: {
            get() {
                return this.$store.getters.getSettingById(`${parseInt(this.status.block)}`) ||
                {              
                  voltage_pos_lo: 1,
                  voltage_pos_hi: 1,
                  voltage_neg_lo: 1,
                  voltage_neg_hi: 1,
                  invert_in_pos: 1,
                  invert_in_neg: 1,
                  count_in_pos: 1,
                  count_in_neg: 1,
                  map_ext_neg: 1,
                  map_ext_pos: 1,
                  address: 1,
                  mode: 1,
                }

            },
            set(nval) {
              this.$store.commit('UPDATE_ANALOG_SETTING', nval)
            }
           
        },
        check() {
          return this.setting.voltage_pos_lo < this.setting.voltage_pos_hi &&
              this.setting?.voltage_neg_lo < this.setting.voltage_neg_hi
        }
  },
}
</script>

<style scoped>
.vinland {
  color: #196619 !important;
}
</style>
